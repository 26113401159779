import React from 'react'
import { Link } from "gatsby"
import Layout from '../Layouts/zh'
import { kotiTitleGenerator } from '../utils'
import SEO from '../components/SEO'

import './terms.scss'

const TermsPage = ({ location }) => (
  <Layout location={location}>
  <SEO
      title={kotiTitleGenerator('条款及细则')}
      description="Delight Labs 致力于保护和尊重您的隐私。"
    />
    <div className="page-terms">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>条款及细则</h1>
              <h4>
                Delight Labs 致力于保护和尊重您的隐私。
              </h4>
              <hr />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-8">
              <article>
                <span className="h4">上次更新时间：2019/10/10</span>
                <p>
                  <h5>
                    本隐私权政策：
                  </h5>
                  <p>
                    FY Innovation Group Limited （以下简称「 Delight Labs」）保留在不另行通知的情况下增加、修改、更改、暂停或永久终止本网站全部或任何部分以及限制或禁止访问它。
                  </p>
                  <p>
                    「个人信息」是指您与可识别个人相关的、由您提供给我们的、或通过您对我们的产品或服务的使用提供的任何数据、信息、或数据与信息的组合； 「平台」是指我们的网站（https://delight.global）； 「Delight Labs」或「我们」的定义在我们的服务条款中。
                  </p>
                </p>
                <p className="type--bold">
                  1. 我们收集有关您的哪些信息
                </p>
                <h5>
                  1.1. 我们收集有关您以下类别的信息：
                </h5>
                <p>
                  a) 您在注册帐户或注册我们的产品或服务时提供的帐户和个人资料信息，例如您的姓名，电子邮件地址，实际地址和电话号码（统称「帐户数据」）；
                </p>
                <p>
                  b) 您通过我们的支持渠道提供的信息，例如，当您向我们报告问题或与我们的支持团队互动时，包括任何联系信息，文档，图像或屏幕截图（统称为「支持数据」）；
                </p>
                <p>
                  c) 您通过使用我们的产品或服务提供的内容，例如您在我们的平台中创建的文档中包含的个人信息（统称为「用户内容」）；

                </p>
                <p>
                  d) 在设置帐户或个人资料时，或者在您参加我们发送给您的调查或问卷时（您统称为「偏好数据」），您设置的沟通，营销和其他偏好；
                </p>
                <p>
                  e) 您与我们进行的任何交易，购买或订单的详细信息（统称为「交易数据」）；
                </p>
                <p>
                  f) 付款信息，例如您的付款卡信息或银行帐户详细信息（统称为「财务数据」）；
                </p>
                <p>
                  g) 有关设备或连接的信息，例如您的互联网协议（IP）地址，登录数据，浏览器类型和版本，时区设置，浏览器插件类型和版本，操作系统和平台以及设备上的其他技术您用于访问我们通过cookie和其他数据收集技术收集的产品或服务以及信息（请阅读我们的Cookies政策以了解详细信息）（统称为「技术数据」）；和
                </p>
                <p>
                  h) 有关您使用或访问我们平台的信息，例如您查看，使用或搜索的产品的点击流，通过我们的平台的点击流，下载或其他错误，页面响应时间，访问时间某些页面，页面交互信息（例如，滚动，单击和鼠标悬停），或从页面上浏览的方法（统称为「使用数据」）。
                </p>
                <h5>
                  1.2. 当您提供给我们或您使用或访问我们的平台时，我们会收集上述信息。我们还可能从其他来源接收有关您的信息，包括：
                </h5>
                <p>
                  a) 与我们的运营或服务有关的我们的人员、代理商和顾问，例如：我们的工作人员正在履行您的订单，处理您的付款以及提供支持服务；
                </p>
                <p>
                  b) 我们产品或服务的其他用户，例如，当您的组织成员在我们的平台上为每个团队成员注册一个帐户时；
                </p>
                <p>
                  c) 与您的帐户关联的其他服务，例如，如果您使用Google凭据注册或登录帐户，我们会在您的Google个人资料设置允许的情况下收到您的姓名和电子邮件地址，以对您进行身份验证；
                </p>
                <p>
                  d) 我们提供信息技术，系统管理和/或营销服务的集团公司或海外办事处；和
                </p>
                <p>
                  e) 我们的业务伙伴和服务供应商谁提供技术，支付，送货服务，广告网络，数据分析，市场调研，和搜索信息服务。
                </p>
                <p>
                  如果您希望购买通过服务提供的任何产品或服务（以下简称「购买」），则可能会要求您提供与购买有关的某些信息，包括但不限于
                </p>
                <h5>
                  1.3 . 我们不会收集有关您的敏感数据或特殊类别数据。这包括有关您的种族，族裔，政治，宗教，工会会员资格，遗传学，生物特征识别，健康或性取向的详细信息。
                </h5>
                <p className="type--bold">2. 我们如何使用收集的信息</p>
                <h5>
                  2.1. 我们仅在法律允许的范围内使用您的个人信息。我们仅在以下情况下使用您的个人信息：
                </h5>
                <p>
                  a) 我们需要履行与您签订（或将要签订的合同）的合同，包括操作我们的产品或服务，提供客户支持和个性化功能以及保护我们平台的安全性；
                </p>
                <p>
                  b) 满足您的基本权利或数据保护利益所不能超越的合法利益，例如，为了改进我们的产品或服务而进行的研究和开发，以及为了保护我们的合法权利和利益；
                </p>
                <p>
                  c) 您已同意我们出于特定目的这样做，例如，在您的允许下，我们可能会向您发送直接营销材料或发布您的信息，作为我们的推荐或客户故事的一部分，以推广我们的产品或服务；或
                </p>
                <p>d) 我们需要遵守法律或监管义务。</p>
                <h5>
                  2.2. 如果您已同意我们将您的个人信息用于特定目的，则您有权随时通过与我们联系来撤回您的同意，但是请注意，这不会影响对您已经使用的信息的任何使用。
                </h5>
                <h5>
                  2.3. 我们不会出于营销目的与我们集团以外的任何公司共享您的个人信息，除非获得您的明确明确同意。
                </h5>
                <p className="type--bold">
                  3. 我们如何共享我们收集的信息
                </p>
                <h5>
                  3.1. 我们与集团中的其他公司共享信息，以操作我们的平台并提供和改善我们的产品和服务。
                </h5>
                <h5>
                  3.2. 我们与帮助我们运营、提供、支持、改进和销售我们产品和服务的第三方共享信息，例如，提供云端服务、保养、备份、储存、架构建设、寄发帐单、付款处理、客户的第三方服务供应商，业务分析及其他服务等。
                </h5>
                <h5>
                  3.3. 第三方服务提供商只能出于执行服务的目的并遵守适用的法律法规来访问您的个人信息。我们要求这些第三方服务提供商维护代表我们处理的所有个人信息的机密性和安全性，并实施和维护合理的安全措施以保护您的个人信息的机密性，完整性和可用性。
                </h5>
                <h5>
                  3.4. 我们采取合理的步骤来确认我们所聘用的所有第三方服务供应商，都以至少提供与本政策相同的保护级别来处理个人信息。如果任何第三方提供商不能满足我们的要求，我们将要求他们立即通知我们，并且我们将采取合理的措施来防止或停止不合规的处理。
                </h5>
                <h5>
                  3.5. 如果您使用与我们的产品或服务有关的任何第三方软件，例如与我们的平台集成的任何第三方软件，则可以向第三方软件提供商授予访问您的帐户和信息的权限。第三方软件提供商的政策和程序不受我们的控制，并且本政策不涵盖第三方软件提供商如何收集或使用您的信息。我们建议您在使用第三方软件之前先阅读第三方软件提供商的隐私政策。
                </h5>
                <h5>
                  3.6. 我们的平台可能包含指向我们无法控制的第三方网站的链接。如果您访问这些网站的链接或向其提交信息，则您的信息将受其政策约束。我们建议您在向第三方网站提交信息之前先阅读其隐私政策。
                </h5>
                <h5>
                  3.7. 我们可能会与政府和执法人员共享您的信息，以遵守适用的法律或法规，例如，当我们响应索赔，法律程序，执法或国家安全要求时。
                </h5>
                <h5>
                  3.8. 如果我们是由于合并，收购或业务转让而被第三方收购，则您的个人信息可能会因该交易而被披露和/或转让给第三方。如果发生此类交易，我们将通知您，并告知您关于信息的任何选择。
                </h5>
                <p className="type--bold">
                  4. 我们如何存储和保护我们收集的信息
                </p>
                <h5>
                  4.1. 我们使用云服务提供商托管我们收集的信息，以使我们能够作为全球平台运行。
                </h5>
                <h5>
                  4.2. 我们采取了以下措施来保护您的个人信息的安全性和完整性：
                </h5>
                <p>
                  a) 您在我们的平台中创建，存储和电子签名的任何文档均以加密形式存储在磁盘上，并通过TLS / SSL加密安全传输；任何付款交易均使用TLS / SSL技术加密；
                </p>
                <p>
                  b) 仅在完全需要了解的情况下，人员或服务提供商才能访问您的个人信息，他们将仅按照我们的指示处理您的信息，并承担保密责任；和
                </p>
                <p>
                  c) 我们的信息采集，存储，加工实践定期审查。
                </p>
                <h5>
                  4.3. 我们已制定程序，以处理任何可疑的隐私违规行为，并将在法律要求的情况下，通知您以及任何适用的监管机构。
                </h5>
                <h5>
                  4.4. 尽管我们实施旨在保护您的信息的安全措施，但请注意，在互联网上传输信息不是绝对安全的。
                </h5>
                <h5>
                  4.5. 我们不能保证您的信息在通过互联网传输或储存在我们的系统中，或由我们处理的过程中绝对安全。我们仅在合理和必要的情况下保留个人信息，以实现我们收集信息的目的，包括出于满足任何法律，会计或报告要求的目的。
                </h5>
                <h5>
                  4.6. 在这段时间之后，我们将删除或匿名化您的信息，或者，如果无法做到，我们将安全地存储您的信息，并使其不再使用。我们会定期审查数据保留政策的基础和适当性。
                </h5>

                <p className="type--bold">
                  5. 我们如何在国际范围内传输信息
                </p>
                <h5>
                  5.1. 我们在全球范围内收集信息。我们会将您的信息转移，处理和存储在您或我们或我们的服务提供商所在的居住国以外的地区，目的是向您提供我们的产品和服务。
                </h5>

                <h5>
                  5.2. 公司或服务提供商所在的某些国家/地区可能没有与您所居住国家/地区相同的隐私和数据保护法律。当我们与这些公司或服务提供商共享信息时，我们会利用合同条款，公司规则和其他适当的机制来保护信息的传输。
                </h5>

                <p className="type--bold">6. 您的权利</p>
                <h5>6.1. 您有权：</h5>
                <p>
                  a) 被告知我们如何处理您的个人信息；
                </p>
                <p>
                  b) 索取我们持有的有关您的个人信息的副本；
                </p>
                <p>
                  c) 要求我们纠正我们持有的有关您的任何个人信息中的任何不准确性或错误；
                </p>
                <p>
                  d) 请求删除您的个人信息（但是，请注意，如果出于我们最初收集该信息的目的，出于记录保存或法律目的仍需要该个人信息，我们可能无法始终符合您的删除要求合规目的）；
                </p>
                <p>
                  e) 反对或限制我们处理您的个人信息（包括出于营销目的）；
                </p>
                <p>
                  f) 请求以结构化，常用且机器可读的格式接收您的某些个人信息，并请求我们将此类信息转移给另一方；和
                </p>
                <p>
                  g) 在这里，我们是靠同意将您的个人信息（虽然这不会影响进行了您撤回同意）之前的任何处理的合法性任何时候撤销同意。
                </p>

                <h5>
                  6.2. 您可以通过使用我们通讯中的退订链接或与我们联系来选择不接收我们的营销材料。但是请注意，即使您选择不接收我们的营销材料，您仍将继续收到我们使用产品或服务所必需的通知或信息。
                </h5>

                <h5>
                  6.3. 作为安全措施，我们可能需要您的特定信息来帮助我们在处理您的隐私请求或行使权利时确认您的身份。
                </h5>
                <h5>
                  6.4. 根据第6.1款提出的任何请求通常都会免费处理。但是，如果您的请求明显没有根据，重复或过多，我们可能会收取合理的管理费。
                </h5>

                <h5 className="type--bold">7. 对本政策的更改</h5>
                <p>
                  我们可能会不时通过在平台上发布更新的政策来修改本政策。更改生效后继续使用我们的平台，即表示您同意受修订后的政策的约束。
                </p>

                <h5 className="type--bold">8. .对儿童的政策</h5>
                <p>
                  我们的产品和服务不针对16岁以下的个人。我们不会有意地从16岁以下的个人那里收集个人信息。如果我们意识到16岁以下的个人为我们提供了个人信息，我们将采取以下措施：删除此类信息。如果您认为我们错误地或无意地从16岁以下的个人那里收集了信息，请与我们联系。
                </p>

                <h5 className="type--bold">9. Cookies政策</h5>
                <p>
                  Cookies是当您访问我们的平台时，由Web服务器放置在设备上的小型文本文件。我们使用Cookie来识别您的访问权限，并在平台上监控使用情况和网络流量，以自定义和改进我们的产品和服务。我们同时使用持久性Cookie和会话Cookie。永久cookie保留在您的浏览器中，当您返回我们的站点或使用我们服务的合作伙伴站点时，它将被我们读取。会话Cookie只会持续到会话持续的时间（通常是当前对网站或浏览器会话的访问）。
                </p>

                <p>我们使用以下类型的cookie：</p>
                <p>
                  a) 非常必要的cookie –这些是我们网站运营所必需的cookie。例如，它们包括cookie，使您能够登录到我们网站的安全区域。
                </p>
                <p>
                  b) 分析/性能cookie –这些cookie使我们能够识别和统计访问者的数量，并查看访问者在使用我们网站时如何在网站上四处走动。例如，通过确保用户可以轻松找到所需的内容，这有助于我们改善网站的工作方式。
                </p>
                <p>
                  c) 功能cookie –当您返回我们的网站时，这些cookie用来识别您。
                </p>
                <p>
                  d) 定位cookie –这些cookie记录您对我们网站的访问，访问过的页面以及您所关注的链接。
                </p>
                <p>
                  您可以通过激活浏览器上的设置来阻止cookie，该设置允许您拒绝使用全部或部分cookie。但是，如果这样做，则可能无法访问我们网站的全部或部分内容。
                </p>
                <p>*此版本为中文翻译本，一切內容以英文版本为准。</p>

                <hr />
                <h5>联系我们</h5>
                <p>
                  如果您对这些条款有任何疑问，请与我们<Link to="/zh/contact">联系</Link>。
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
    {/* <button
      className="back-to-top inner-link active"
      onClick={() => animateScroll.scrollToTop()}
    >
      <i className="stack-interface stack-up-open-big" />
    </button> */}
    {/* <CallToAction_01/> */}
  </Layout>
)

export default TermsPage
